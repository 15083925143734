import { Typography } from '@mui/material';
import { css, Stack } from '@mui/system';
import { RouteLink, Types } from '@scorenco/core';

import { Wrapper, Image } from '../../primitives';

type LinkListSliceProps = {
  slice: Types.ComponentSlicesLinkList;
};

export const LinkListSlice = ({ slice }: LinkListSliceProps) => {
  return (
    <Wrapper marginY={5}>
      <Stack
        flexWrap="wrap"
        direction="row"
        justifyContent="center"
        mx={-1}
        my={-1}
      >
        {slice.liens?.map((link) => {
          if (!link) {
            return null;
          }

          return (
            <RouteLink key={link.id} href={link.link}>
              <Stack
                component="a"
                gap={2}
                direction="row"
                alignItems="center"
                py={1}
                px={2}
                mx={2}
                my={1}
                borderRadius={2}
                css={(theme) => css`
                  text-decoration: none;
                  background-color: ${theme.vars.palette.background.default};
                  mix-blend-mode: ${slice.color ? 'normal' : 'luminosity'};
                  transition: box-shadow mix-blend-mode 0.2s;

                  &,
                  &:visited {
                    color: unset;
                  }

                  &:hover {
                    background-color: ${theme.vars.palette.background.paper};
                    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.07);
                    mix-blend-mode: normal;
                  }
                `}
              >
                <Image
                  src={link.logo?.data?.attributes?.url}
                  alt={link.logo?.data?.attributes?.alternativeText}
                  css={css`
                    width: 6rem;
                    height: 4rem;
                    object-fit: contain;
                  `}
                />
                {link.title && <Typography>{link.title}</Typography>}
              </Stack>
            </RouteLink>
          );
        })}
      </Stack>
    </Wrapper>
  );
};
